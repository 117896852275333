import React, { useEffect, useState } from 'react';
import {
  Button,
  SlideOver,
  Empty,
  Badge,
  Pagination,
  Modal,
  CheckboxGroup,
  Checkbox,
  Loading,
  Alert,
  Select,
} from '@factory5/ui-kit';
import { useStore } from 'effector-react';
import { useParams } from 'react-router-dom';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Tooltip } from 'ui';

import {
  ControlProposedTripStatus,
  ControlTripObject,
  ControlTripsSectionEnum,
  VerdictActionEnum,
} from 'api';

import {
  $controlTripListRequestParams,
  $controlTripListStore,
  $verdictReasons,
  changeControlTripListRequestParams,
  getControlTripsFx,
  fetchVerdictReasonsFx,
  setTripVerdictFx,
  changeApproveType,
  $approveType,
  changeRejectType,
  $rejectType,
  commitAllVerdictsFx,
  resetAllVerdictsFx,
} from 'features/stations/models/controlTrips.model';
import { fetchControlTripsFiltersFx, $selectedFilters } from 'features/filters';
import { DislocationRow } from 'features/stations/components';

import { ActionType, ControlTripStatus } from '../../models/controlTrips.types';
import ApproveTripsFilterProvider from '../ApproveTripsFilterProvider';
import TripsControl from '../TripsControl';

const getTripType = (
  button: VerdictActionEnum,
  statusData?: ControlProposedTripStatus,
) => {
  if (!statusData) {
    return undefined;
  }

  if (!statusData.first_leg) {
    return undefined;
  }

  if (
    (button === VerdictActionEnum.Approve &&
      statusData.status === ControlTripStatus.PRE_APPROVED) ||
    (button === VerdictActionEnum.Decline &&
      statusData.status === ControlTripStatus.PRE_REJECTED)
  ) {
    return 'primary';
  }

  return undefined;
};

const compareStatuses = (
  currentStatus: number,
  action: VerdictActionEnum,
): boolean => {
  // 3 - PRE_APPROVED
  // 4 - PRE_REJECTED
  if (currentStatus === 3 && action === VerdictActionEnum.Approve) {
    return true;
  }

  if (currentStatus === 4 && action === VerdictActionEnum.Decline) {
    return true;
  }

  return false;
};

type ApproveTripsSlideOverProps = {
  params: {
    date?: string;
    dates?: [string, string];
    type: string;
    subgroupId?: number[];
    groupId?: number[];
    overrideFilters?: { [key: string]: any };
  };

  onClose: () => void;
};

export default function ApproveTripsSlideOver({
  params,
  onClose,
}: ApproveTripsSlideOverProps) {
  const { id: stationId } = useParams();
  const selectedFilters = useStore($selectedFilters)['controlTrips'];
  const selectedCargo = useStore($selectedFilters)['station'].cargo as
    | number[]
    | undefined;
  const [isModalShow, setIsModalShow] = useState(false);

  const [tempVerdictData, setTempVerdictData] = useState<{
    ids?: number[];
    action: VerdictActionEnum;
  } | null>(null);
  const [rejectCandidate, setRejectCandidate] = useState<ControlTripObject>();
  const [rejectReasons, setRejectReasons] = useState<number[]>([]);
  const [rejectManyData, setRejectManyData] = useState<{
    value: string;
    action: VerdictActionEnum;
    ids?: number[];
    reasons?: number[];
  }>();

  const verdictReasons = useStore($verdictReasons);
  const isVerdictReasonsPending = useStore(fetchVerdictReasonsFx.pending);

  const controlTripParams = useStore($controlTripListRequestParams);
  const tripsPending = useStore(getControlTripsFx.pending);

  const approveType = useStore($approveType);
  const rejectType = useStore($rejectType);

  const isCommitPnding = useStore(commitAllVerdictsFx.pending);
  const isResetPnding = useStore(resetAllVerdictsFx.pending);

  const {
    objects: controlTrips,
    proposed_station,
    product_subgroup_names,
    page,
    page_size,
    objects_count,
    objects_total_count,
    approved_trips,
    rejected_trips,
  } = useStore($controlTripListStore);

  useEffect(() => {
    if (stationId && Number(stationId)) {
      getControlTripsFx({
        ...controlTripParams,
        stationId: Number(stationId),
        date: params.date,
        subgroupId: params.subgroupId,
        groupId: params.groupId ? params.groupId : selectedCargo,
        section: params.type as ControlTripsSectionEnum,
        ...selectedFilters,
        ...(params.overrideFilters ? params.overrideFilters : {}),
      });
    }
  }, [params, stationId, selectedFilters, controlTripParams]);

  useEffect(() => {
    // Если при загрузке панели страница не с начала, то меняем на первую страницу
    if (controlTripParams.page !== 1)
      changeControlTripListRequestParams({ page: 1 });

    fetchControlTripsFiltersFx();
    fetchVerdictReasonsFx();

    return () => {
      changeApproveType(ActionType.manual);
      changeRejectType(ActionType.manual);
    };
  }, []);

  const actionToggleHandler = async (
    controlTrip: ControlTripObject,
    action: VerdictActionEnum,
    reasons?: number[],
  ) => {
    // 1
    setTempVerdictData((prev) => ({
      ids: [
        ...(prev && prev.ids ? prev.ids : []),
        (controlTrip.proposed_trip_status as ControlProposedTripStatus).id,
      ],
      action: compareStatuses(
        (controlTrip.proposed_trip_status as ControlProposedTripStatus).status,
        action,
      )
        ? VerdictActionEnum.Reset
        : action,
    }));
    // 2
    await setTripVerdictFx({
      action: compareStatuses(
        (controlTrip.proposed_trip_status as ControlProposedTripStatus).status,
        action,
      )
        ? VerdictActionEnum.Reset
        : action,
      ids: [(controlTrip.proposed_trip_status as ControlProposedTripStatus).id],
      reasons: reasons ? reasons : undefined,
      station_id: Number(stationId),
      subgroup_id: params.subgroupId,
      date: params.date,
    });
    // 3
    await getControlTripsFx({
      ...controlTripParams,
      stationId: Number(stationId),
      date: params.date,
      subgroupId: params.subgroupId,
      groupId: params.groupId,
      section: params.type as ControlTripsSectionEnum,
      ...selectedFilters,
      ...(params.overrideFilters ? params.overrideFilters : {}),
    });
    // 4
    setTempVerdictData(null);
    if (reasons) {
      setRejectCandidate(undefined);
      setRejectReasons([]);
    }
  };

  const toggleAllHandler = async (
    value: string,
    action: VerdictActionEnum,
    ids?: number[],
    reasons?: number[],
  ) => {
    // 1
    setTempVerdictData({
      ids: ids ? ids : undefined,
      action: value === 'nothing' ? VerdictActionEnum.Reset : action,
    });
    // 2
    await setTripVerdictFx({
      action: value === 'nothing' ? VerdictActionEnum.Reset : action,
      ids: ids ? ids : undefined,
      reasons: reasons ? reasons : undefined,
      station_id: Number(stationId),
      subgroup_id: params.subgroupId,
      date: params.date,
    });
    // 3
    await getControlTripsFx({
      ...controlTripParams,
      stationId: Number(stationId),
      date: params.date,
      subgroupId: params.subgroupId,
      groupId: params.groupId,
      section: params.type as ControlTripsSectionEnum,
      ...selectedFilters,
      ...(params.overrideFilters ? params.overrideFilters : {}),
    });
    // 4
    setTempVerdictData(null);
  };

  const confirmAllHandler = (
    value: string,
    action: VerdictActionEnum,
    ids?: number[],
    reasons?: number[],
  ) => {
    if (value !== 'nothing') {
      setRejectManyData({
        value,
        action,
        ids,
        reasons,
      });
      setIsModalShow(true);
    }
  };

  const getReasons = (reasons: number[]) => {
    if (verdictReasons) {
      const findedReasons = verdictReasons
        .filter((item) => reasons.includes(item.value))
        .map((item) => item.title);

      return findedReasons.join(', ');
    }
    return '';
  };

  return (
    <SlideOver
      autoOpen
      position="right"
      width={100}
      panelClassName="!pt-0"
      header={
        <div className="bg-gray-50 px-6 py-6 font-medium">
          {params.type === ControlTripsSectionEnum.EmptyProposed
            ? 'Одобрение рейсов'
            : 'Просмотр текущей дислокации'}
        </div>
      }
      onClose={onClose}
    >
      <div className="sticky top-0 bg-white pt-3 z-[51]">
        <div className="flex flex-row gap-10 items-center justify-between">
          <div className="flex flex-row gap-10 items-center">
            <div className="flex flex-col shrink-0">
              <div className="text-sm uppercase">
                {proposed_station?.railway}
              </div>
              <div className="text-md font-semibold uppercase">
                {proposed_station?.name}
              </div>
              <div className="text-gray-600">{proposed_station?.code}</div>
            </div>

            <div className="text-base font-semibold shrink-0">
              {params.date}
            </div>

            <div className="flex flex-wrap gap-2">
              {product_subgroup_names &&
                product_subgroup_names.map((i) => (
                  <Badge color="indigo" size="lg" key={i}>
                    {i}
                  </Badge>
                ))}
            </div>
          </div>

          {params.type === ControlTripsSectionEnum.EmptyProposed && (
            <TripsControl
              approveManyHandler={toggleAllHandler}
              rejectManyHandler={confirmAllHandler}
            />
          )}
        </div>

        <div className="mt-8 mb-5">
          <ApproveTripsFilterProvider />
        </div>
      </div>

      <div className="flex flex-col gap-5">
        {controlTrips.length > 0 && (
          <>
            {controlTrips.map((controlTrip) => (
              <div
                className="flex items-center gap-3"
                key={JSON.stringify(controlTrip)}
              >
                <div className="border rounded-md border-slate-100 w-full">
                  <DislocationRow
                    wagonDeployment={controlTrip.current_trip}
                    filterKey={'controlTrips'}
                  />
                </div>
                {params.type === ControlTripsSectionEnum.EmptyProposed && (
                  <div className="flex flex-col gap-3">
                    {controlTrip.proposed_trip_status?.status ===
                      ControlTripStatus.APPROVED && <CheckIcon />}
                    {controlTrip.proposed_trip_status?.status ===
                      ControlTripStatus.REJECTED && <XMarkIcon />}
                    {controlTrip.proposed_trip_status?.status !==
                      ControlTripStatus.APPROVED && (
                      <Tooltip
                        content={
                          controlTrip.proposed_trip_status?.status ===
                          ControlTripStatus.PRE_APPROVED
                            ? 'Рейс предварительно одобрен'
                            : 'Одобрить рейс'
                        }
                      >
                        <Button
                          icon={<CheckIcon />}
                          size="sm"
                          className="block"
                          disabled={
                            !controlTrip.proposed_trip_status?.first_leg
                          }
                          type={getTripType(
                            VerdictActionEnum.Approve,
                            controlTrip.proposed_trip_status,
                          )}
                          pending={
                            tempVerdictData &&
                            (!tempVerdictData.ids ||
                              (tempVerdictData.ids &&
                                tempVerdictData.ids.includes(
                                  (
                                    controlTrip.proposed_trip_status as ControlProposedTripStatus
                                  ).id,
                                ))) &&
                            (tempVerdictData.action ===
                              VerdictActionEnum.Approve ||
                              (tempVerdictData.action ===
                                VerdictActionEnum.Reset &&
                                controlTrip.proposed_trip_status?.status ===
                                  ControlTripStatus.PRE_APPROVED))
                              ? true
                              : false
                          }
                          onClick={() => {
                            if (approveType !== ActionType.manual) {
                              changeApproveType(ActionType.manual);
                            }
                            actionToggleHandler(
                              controlTrip,
                              VerdictActionEnum.Approve,
                            );
                          }}
                        />
                      </Tooltip>
                    )}
                    {controlTrip.proposed_trip_status?.status !==
                      ControlTripStatus.REJECTED && (
                      <Tooltip
                        content={
                          controlTrip.proposed_trip_status?.status ===
                          ControlTripStatus.PRE_REJECTED
                            ? 'Рейс предварительно отклонен'
                            : 'Отклонить рейс'
                        }
                      >
                        <Button
                          icon={<XMarkIcon />}
                          size="sm"
                          destructive
                          className="block"
                          disabled={
                            !controlTrip.proposed_trip_status?.first_leg
                          }
                          type={getTripType(
                            VerdictActionEnum.Decline,
                            controlTrip.proposed_trip_status,
                          )}
                          pending={
                            tempVerdictData &&
                            (!tempVerdictData.ids ||
                              (tempVerdictData.ids &&
                                tempVerdictData.ids.includes(
                                  (
                                    controlTrip.proposed_trip_status as ControlProposedTripStatus
                                  ).id,
                                ))) &&
                            (tempVerdictData.action ===
                              VerdictActionEnum.Decline ||
                              (tempVerdictData.action ===
                                VerdictActionEnum.Reset &&
                                controlTrip.proposed_trip_status?.status ===
                                  ControlTripStatus.PRE_REJECTED))
                              ? true
                              : false
                          }
                          onClick={() => {
                            if (
                              controlTrip.proposed_trip_status &&
                              controlTrip.proposed_trip_status.status ===
                                ControlTripStatus.PRE_REJECTED
                            ) {
                              actionToggleHandler(
                                controlTrip,
                                VerdictActionEnum.Decline,
                              );
                              if (rejectType !== ActionType.manual) {
                                changeRejectType(ActionType.manual);
                              }
                            } else {
                              setIsModalShow(true);
                              setRejectCandidate(controlTrip);
                            }
                          }}
                        />
                      </Tooltip>
                    )}
                  </div>
                )}
              </div>
            ))}
            <div className="flex justify-center mb-6 relative h-12">
              <Pagination
                current={page}
                pageSize={page_size}
                total={objects_count || 0}
                onChange={(page) => {
                  changeControlTripListRequestParams({
                    page: page.current,
                  });
                }}
              />
              <div className="absolute right-0 w-20">
                <Select
                  search={false}
                  options={[
                    {
                      label: '25',
                      value: 25,
                    },
                    {
                      label: '50',
                      value: 50,
                    },
                    {
                      label: '100',
                      value: 100,
                    },
                  ]}
                  value={controlTripParams.pageSize}
                  dropdownClassName="bottom-12"
                  onSelect={(pageSize) =>
                    changeControlTripListRequestParams({
                      page: 1,
                      pageSize: Number(pageSize),
                    })
                  }
                  editable={false}
                />
              </div>
            </div>
          </>
        )}
        {controlTrips.length === 0 && tripsPending && (
          <div className="flex justify-center my-10">
            <Loading />
          </div>
        )}
        {controlTrips.length === 0 && !tripsPending && (
          <div className="mb-10">
            <Empty />
          </div>
        )}
        {/* <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex gap-2 w-full rounded-lg bg-purple-100 text-left text-sm font-semibold uppercase">
                <ChevronUpIcon
                  className={`${open ? 'rotate-180 transform' : ''} h-5 w-5`}
                />
                <span>
                  Индекс поезда:{' '}
                  <span className="font-normal">№2800-259-2857</span> Станция
                  операции: <span className="font-normal">ОРСК</span>
                </span>
              </Disclosure.Button>

              <Disclosure.Panel className="px-4 pt-4 pb-2">
                <TripControlRow />
              </Disclosure.Panel>
            </>
          )}
        </Disclosure> */}
      </div>
      {params.type === ControlTripsSectionEnum.EmptyProposed && (
        <div className="backdrop-blur-sm bg-slate-50/90 px-4 py-4 sticky -bottom-0 left-0 z-50 flex justify-between items-center rounded-lg">
          <div className="flex gap-5 items-center text-sm">
            <div>Рейсов: {objects_count}</div>
            <div className="w-px h-4 bg-slate-200"></div>
            <div>
              <Badge size="lg" color="emerald">
                К одобрению: {approved_trips}
              </Badge>
            </div>
            <div className="w-px h-4 bg-slate-200"></div>
            <div>
              <Badge size="lg" color="red">
                К отклонению: {rejected_trips}
              </Badge>
            </div>
          </div>
          <div className="flex gap-5">
            {/* <Tooltip content="Предварительный просмотр">
              <Button icon={<EyeIcon />}></Button>
            </Tooltip> */}
            <Button
              type="primary"
              pending={isCommitPnding}
              onClick={async () => {
                await commitAllVerdictsFx({
                  station_id: Number(stationId),
                  subgroup_id: params.subgroupId,
                  date: params.date,
                });

                getControlTripsFx({
                  ...controlTripParams,
                  stationId: Number(stationId),
                  date: params.date,
                  subgroupId: params.subgroupId,
                  groupId: params.groupId,
                  section: params.type as ControlTripsSectionEnum,
                  ...selectedFilters,
                  ...(params.overrideFilters ? params.overrideFilters : {}),
                });
              }}
            >
              Подтвердить решения
            </Button>
            <Button
              type="primary"
              destructive
              pending={isResetPnding}
              onClick={async () => {
                await resetAllVerdictsFx({
                  station_id: Number(stationId),
                  subgroup_id: params.subgroupId,
                  date: params.date,
                });
                changeApproveType(ActionType.manual);
                changeRejectType(ActionType.manual);

                getControlTripsFx({
                  ...controlTripParams,
                  stationId: Number(stationId),
                  date: params.date,
                  subgroupId: params.subgroupId,
                  groupId: params.groupId,
                  section: params.type as ControlTripsSectionEnum,
                  ...selectedFilters,
                  ...(params.overrideFilters ? params.overrideFilters : {}),
                });
              }}
            >
              Сбросить все
            </Button>
          </div>
        </div>
      )}
      <Modal
        visible={isModalShow}
        title="Укажите причину отклонения рейса"
        zIndex={10000}
        onDismiss={() => {
          setIsModalShow(false);
        }}
        footer={
          <div className="flex gap-4 justify-end">
            <Button
              destructive
              type="primary"
              onClick={() => {
                if (rejectCandidate) {
                  actionToggleHandler(
                    rejectCandidate,
                    VerdictActionEnum.Decline,
                    rejectReasons,
                  );
                  setIsModalShow(false);
                  setRejectReasons([]);
                } else {
                  if (rejectManyData) {
                    toggleAllHandler(
                      rejectManyData.value,
                      rejectManyData.action,
                      rejectManyData.ids,
                      rejectReasons,
                    );
                    setIsModalShow(false);
                    setRejectReasons([]);
                  }
                }
              }}
            >
              Отклонить
            </Button>
            <Button
              onClick={() => {
                setRejectReasons([]);
                setIsModalShow(false);
              }}
            >
              Не отклонять
            </Button>
          </div>
        }
      >
        {isVerdictReasonsPending && (
          <div className="flex justify-center py-5">
            <Loading />
          </div>
        )}
        {verdictReasons.length > 0 && (
          <CheckboxGroup
            values={rejectReasons}
            onChange={(values) => setRejectReasons(values as number[])}
          >
            {verdictReasons.map((item) => (
              <Checkbox value={item.value} key={item.value}>
                {item.title}
              </Checkbox>
            ))}
          </CheckboxGroup>
        )}
      </Modal>
    </SlideOver>
  );
}
